/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import "../components/Sidebar.css";
import { TfiAlignJustify } from "react-icons/tfi";
import { IoIosContact } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';

const Sidebar = ({ setIsLoggedIn, userName }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    <>
      <li className="list">
        <p onClick={toggleSidebar}>
          <TfiAlignJustify />Open Sidebar
        </p>
      </li>

      <div className="maindiv">
      <li className="list">
          
          <span>Welcome,{userName}</span>
         
        </li>
        <li className="list">
          <IoIosContact />
          <Link to='/Profile'>Profile</Link>
        </li>
        <li className="list">
          <IoIosContact />
          <Link to='/Addblogs'>Add Blogs</Link>
        </li>
        <li className="list">
          <IoIosContact />
          <Link to='/Edityourblogs'>Edit your blogs</Link>
        </li>
        <li className="list">
          <IoIosContact />
          <a onClick={handleLogout}>Logout</a>
        </li>
      </div>
    </>
  );
};

export default Sidebar;
