import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Auth.css'; 

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !password || !employeeId) {
      setError('All fields are required');
      return;
    }
    if (!isValidEmail(email)) {
      setError('Please use a company email address');
      return;
    }
  
    try {
      const response = await fetch('https://blog-backend-ivfv.onrender.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password, employeeId }),
      });
  
      if (response.ok) {
        setError('');
        setSuccessMessage('Email Verification link has been sent to your email.');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        
        const data = await response.json();
        setError(data.error || 'Registration failed');
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setError('Internal Server Error');
    }
  };
  

  const isValidEmail = (email) => {
    return /\b@(walsystems\.in|walsystems\.com|bestowal\.in|bestowal\.com)\b/.test(email);
  };
  

  return (
    <div className="auth-container">
      <h2>Register</h2>
      {error && <p className="error">{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      <form onSubmit={handleSubmit} className="auth-form">
        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <input type="text" placeholder="Employee ID" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} />
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit">Register</button>
      </form>
      <p>Already have an account? <Link to="/login">Login</Link></p>
      
    </div>
  );
};

export default Register;
