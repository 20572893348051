import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";

// Custom clipboard handler
class Clipboard extends Quill.import("modules/clipboard") {
  async onPaste(e) {
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let item of items) {
      if (item.kind === "file" && item.type.startsWith("image/")) {
        e.preventDefault();
        const file = item.getAsFile();
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await fetch("https://blog-backend-ivfv.onrender.com/upload-image", {
            method: "POST",
            body: formData,
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          const relativeUrl = data.url;
          const baseUrl = "https://blog-backend-ivfv.onrender.com";
          const absoluteUrl = `${baseUrl}${relativeUrl}`;

          const range = this.quill.getSelection();
          this.quill.insertEmbed(range.index, "image", absoluteUrl);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    }
    super.onPaste(e);
  }
}

Quill.register("modules/clipboard", Clipboard, true);

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "" }],
      ["link", "image"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ header: 1 }, { header: 2 }],
      [{ "line-height": [] }],
      ["clean"],
    ],
    handlers: {
      image: imageHandler,
    },
  },
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
];
async function imageHandler() {
  const fileInput = document.createElement("input");
  fileInput.setAttribute("type", "file");
  fileInput.setAttribute(
    "accept",
    "image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/webp, image/tiff, image/svg+xml"
  );
  fileInput.classList.add("ql-image");

  fileInput.addEventListener("change", async () => {
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await fetch("https://blog-backend-ivfv.onrender.com/upload-image", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const relativeUrl = data.url;
        const baseUrl = "https://blog-backend-ivfv.onrender.com";
        const absoluteUrl = `${baseUrl}${relativeUrl}`;

        const range = this.quill.getSelection();
        this.quill.insertEmbed(range.index, "image", absoluteUrl);

        fileInput.value = "";
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  });

  fileInput.click();
}
function EditBlogs() {
  const { id } = useParams();
  const [blogTitle, setBlogTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [author, setAuthor] = useState({
    name: "",
    email: "",
    description: "",
    profilePic: "",
  });
  const quillRef = useRef(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`https://blog-backend-ivfv.onrender.com/blogs/${id}`);

        const blogData = response.data;
        setBlogTitle(blogData.title);
        setSummary(blogData.summary);
        setContent(blogData.content);
        setImage(blogData.image);
        setCategory(blogData.category);
        setAuthor(
          blogData.author || {
            name: "",
            email: "",
            description: "",
            profilePic: "",
          }
        ); // Use default if author is not present
      } catch (error) {
        console.error("Error fetching blog data:", error);
        setResponseMessage("Error fetching blog data.");
      }
    };

    fetchBlogData();
  }, [id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleAuthorChange = (e) => {
    const { name, value } = e.target;
    setAuthor((prevAuthor) => ({
      ...prevAuthor,
      [name]: value,
    }));
  };

  const updateBlog = async () => {
    try {
      const response = await axios.put(`https://blog-backend-ivfv.onrender.com/blogs/${id}`, {
        title: blogTitle,
        summary,
        content,
        image,
        category,
        author,
      });

      if (response.status === 200) {
        setResponseMessage("Blog updated successfully!");
      } else {
        setResponseMessage("Error updating blog.");
      }
    } catch (error) {
      console.error("Error updating blog:", error);
      setResponseMessage("Error updating blog.");
    }
  };
  const togglePreview = () => {
    setShowPreview(!showPreview);
  };
  return (
    <div className="App">
      <div>
        <input
          type="text"
          value={blogTitle}
          onChange={(e) => setBlogTitle(e.target.value)}
          placeholder="Title"
        />
      </div>
      <div>
        <input
          type="text"
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
          placeholder="Summary"
        />
      </div>
      <div>
        <input type="file" onChange={handleImageChange} />
      </div>
      <div>
        <ReactQuill
          ref={quillRef}
          value={content}
          onChange={(value) => setContent(value)}
          modules={modules}
          formats={formats}
        />
      </div>
      <div>
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="Edit or create Category"
        />
      </div>
      <h2>Author Details</h2>
      <div>
        <input
          type="text"
          name="name"
          value={author.name}
          onChange={handleAuthorChange}
          placeholder="Author Name"
        />
      </div>
      <div>
        <input
          type="text"
          name="email"
          value={author.email}
          onChange={handleAuthorChange}
          placeholder="Author Email"
        />
      </div>
      <div>
        <textarea
          name="description"
          value={author.description}
          onChange={handleAuthorChange}
          placeholder="Author Description"
        ></textarea>
      </div>

      <button onClick={updateBlog} className="button">
        Update Blog
      </button>
      <button onClick={togglePreview} className="button">
        {showPreview ? "Hide Preview" : "Show Preview"}
      </button>
      {responseMessage && <p id="responsemessage">{responseMessage}</p>}

      {showPreview && (
        <div className="preview-section">
          <h2>Preview content</h2>
          <div className="preview-content">
            <h3>{blogTitle}</h3>
            <p>{summary}</p>
            {image && (
              <img src={`data:image/jpeg;base64,${image}`} alt="Preview" />
            )}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default EditBlogs;
