import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

// Custom clipboard handler
class Clipboard extends Quill.import("modules/clipboard") {
  async onPaste(e) {
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let item of items) {
      if (item.kind === "file" && item.type.startsWith("image/")) {
        e.preventDefault();
        const file = item.getAsFile();
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await fetch("https://blog-backend-ivfv.onrender.com/upload-image", {
            method: "POST",
            body: formData,
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          const relativeUrl = data.url;
          const baseUrl = "https://blog-backend-ivfv.onrender.com";
          const absoluteUrl = `${baseUrl}${relativeUrl}`;

          const range = this.quill.getSelection();
          this.quill.insertEmbed(range.index, "image", absoluteUrl);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    }
    super.onPaste(e);
  }
}

Quill.register("modules/clipboard", Clipboard, true);

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "" }],
      ["link", "image"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ header: 1 }, { header: 2 }],
      [{ "line-height": [] }],
      ["clean"],
    ],
    handlers: {
      image: imageHandler,
    },
  },
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "line-height",
];

async function imageHandler() {
  const fileInput = document.createElement("input");
  fileInput.setAttribute("type", "file");
  fileInput.setAttribute(
    "accept",
    "image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/webp, image/tiff, image/svg+xml"
  );
  fileInput.classList.add("ql-image");

  fileInput.addEventListener("change", async () => {
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await fetch("https://blog-backend-ivfv.onrender.com/upload-image", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const relativeUrl = data.url;
        const baseUrl = "https://blog-backend-ivfv.onrender.com";
        const absoluteUrl = `${baseUrl}${relativeUrl}`;

        const range = this.quill.getSelection();
        this.quill.insertEmbed(range.index, "image", absoluteUrl);

        fileInput.value = "";
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  });

  fileInput.click();
}

function AddNewBlog({ userEmail }) {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorDes, setAuthorDes] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const quillRef = useRef(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `https://blog-backend-ivfv.onrender.com/users/${userEmail}`
        );
        setUserDetails(response.data);
        setAuthorName(response.data.name);
        setAuthorEmail(response.data.email);
        setAuthorDes(response.data.description);
      } catch (error) {
        setError("Error fetching user details");
      }
    };

    if (userEmail) {
      fetchUserDetails();
    }
  }, [userEmail]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://blog-backend-ivfv.onrender.com/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // const createBlog = async () => {
  //   try {
  //     const response = await axios.post("https://blog-backend-ivfv.onrender.com/blogs", {
  //       title,
  //       summary,
  //       content,
  //       image,
  //       category: newCategory || category,
  //       author: {
  //         name: authorName,
  //         email: authorEmail,
  //         description: authorDes,
  //         profilePic: userDetails?.profilePic,
  //       },
  //     });

  //     if (response.status === 201) {
  //       setResponseMessage("Blog created successfully!");
  //       setTitle("");
  //       setSummary("");
  //       setContent("");
  //       setImage("");
  //       setCategory("");
  //       setNewCategory("");
  //     } else {
  //       setResponseMessage("Error creating Blog.");
  //     }
  //   } catch (error) {
  //     console.error("Error creating Blog:", error);
  //     setResponseMessage("Error creating Blog.");
  //   }
  // };
  const createBlog = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("summary", summary);
    formData.append("content", content);
    formData.append("category", newCategory || category);
    formData.append(
      "author",
      JSON.stringify({
        name: authorName,
        email: authorEmail,
        description: authorDes,
        profilePic: userDetails?.profilePic,
      })
    );
    if (image) formData.append("image", image);
    if (thumbnail) formData.append("thumbnail", thumbnail);

    try {
      const response = await axios.post(
        "https://blog-backend-ivfv.onrender.com/blogs",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        setResponseMessage("Blog created successfully!");
        setTitle("");
        setSummary("");
        setContent("");
        setImage("");
        setThumbnail("");
        setCategory("");
        setNewCategory("");
      } else {
        setResponseMessage("Error creating Blog.");
      }
    } catch (error) {
      console.error("Error creating Blog:", error);
      setResponseMessage("Error creating Blog.");
    }
  };

  if (!userEmail) {
    return <div className="App">Email not provided</div>;
  }

  if (error) {
    return <div className="App">{error}</div>;
  }

  if (!userDetails) {
    return <div className="App">Loading...</div>;
  }

  const profilePicURL = `https://blog-backend-ivfv.onrender.com/${userDetails.profilePic}`;

  return (
    <div className="App">
      <h1>Add Your Blog</h1>
      <div className="choosefile">
        <label htmlFor="thumbnail">Add Thumbnail:</label>
        <input
          type="file"
          id="thumbnail"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setThumbnail(file); // Set File object directly
            }
          }}
        />
      </div>

      <div>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
        />
      </div>
      <div>
        <input
          type="text"
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
          placeholder="Summary"
        />
      </div>

      <div>
        <div className="category">
          <label htmlFor="category">Category:</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select Category...</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>

        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="Or create new category"
        />
      </div>
      <div>
        <ReactQuill
          ref={quillRef}
          value={content}
          onChange={setContent}
          modules={modules}
          formats={formats}
        />
      </div>
      <div>
        <br />
        <h2>Author</h2>
        <input
          type="text"
          value={authorName}
          onChange={(e) => setAuthorName(e.target.value)}
          placeholder="Author Name"
        />
        <input
          type="text"
          value={authorEmail}
          onChange={(e) => setAuthorEmail(e.target.value)}
          placeholder="Author Email"
        />
        <input
          type="text"
          value={authorDes}
          onChange={(e) => setAuthorDes(e.target.value)}
          placeholder="Author Description"
        />
      </div>
      <button onClick={createBlog} className="button">
        Create Blog
      </button>
      {responseMessage && <p id="responsemessage">{responseMessage}</p>}
      <div className="preview-section">
        <h2>Preview content</h2>
        <div className="preview-content">
          <h3>Author: {userDetails.name}</h3>
          <h4>Description: {userDetails.description}</h4>
          <img
            src={profilePicURL}
            alt="Profile"
            style={{ width: "200px", height: "200px" }}
          />
          <h3>{title}</h3>
          <p>{summary}</p>
          {image && (
            <img src={`https://blog-backend-ivfv.onrender.com/${image}`} alt="Preview" />
          )}
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
}

export default AddNewBlog;
