import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Button from "react-bootstrap/Button";

const Edityourblogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const authorEmail = localStorage.getItem('useremail'); // Retrieve user's email from localStorage
      const response = await axios.get('https://blog-backend-ivfv.onrender.com/blogs', {
        params: {
          authorEmail: authorEmail // Pass user's email as a parameter
        }
      });
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching blogs data:', error);
      setError('Error fetching blogs data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  

  const handleDelete = async (id) => {
    try {
      // Make API call to delete data on the backend
      await axios.delete(`https://blog-backend-ivfv.onrender.com/blogs/${id}`);
      // Remove the deleted item from the frontend state
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog._id !== id));
    } catch (error) {
      console.error('Error deleting blog:', error.response); // Log the error details
      setError('Error deleting blog. Please try again later.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='editSection'>
      <h1>Blog List</h1>
      <ul>
        {blogs.map((blog) => (
          <li key={blog._id}>
            <span className='blogname'>{blog.title}</span>
            <div className='secbtns'>
              <Link to={`/editblog/${blog._id}`}>Edit</Link>
              <Button variant="primary" className='btnnew' onClick={() => handleDelete(blog._id)}>Delete</Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Edityourblogs;
