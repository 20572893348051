import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css"; 
import Sidebar from "./components/Sidebar";
import Addblogs from "./components/Addblogs";
import Edityourblogs from "./components/Edityourblogs";
import Editblogs from "./components/Editblogs";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import ForgotPassword from "./components/ForgotPassword"
import ResetPassword from "./components/Resetpassword";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  return (
    <div className="app-container">
      {isLoggedIn && <Sidebar setIsLoggedIn={setIsLoggedIn} userName={userName} />}
      
      <div className="content-container">
        <Routes>
          <Route path="/Addblogs" element={isLoggedIn ? <Addblogs userEmail={userEmail}/> : <Navigate to="/login" />} />
          <Route path="/profile" element={isLoggedIn ? <Profile userEmail={userEmail} /> : <Navigate to="/login" />} />
          <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setUserName={setUserName} setUserEmail={setUserEmail} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword/>} />
          <Route path="/Edityourblogs" element={isLoggedIn ? <Edityourblogs /> : <Navigate to="/login" />} />
          <Route path="/editblog/:id" element={<Editblogs />} />
          <Route path="/" element={isLoggedIn ? <Addblogs /> : <Navigate to="/login" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
