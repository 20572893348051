import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profile = ({ userEmail }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [updatedDetails, setUpdatedDetails] = useState({});
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [profilePic, setProfilePic] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`https://blog-backend-ivfv.onrender.com/users/${userEmail}`);
        setUserDetails(response.data);
        setUpdatedDetails(response.data);
      } catch (error) {
        setError('Error fetching user details');
      }
    };

    if (userEmail) {
      fetchUserDetails();
    }
  }, [userEmail]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDetails({ ...updatedDetails, [name]: value });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      // Make a request to update user details
      const response = await axios.put(`https://blog-backend-ivfv.onrender.com/users/${userDetails._id}`, updatedDetails);
      // If successful, update userDetails and exit edit mode
      setUserDetails(response.data);
      setIsEditing(false);
    } catch (error) {
      setError('Error updating user details');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfilePic(file);
  };

  const handleUploadClick = async () => {
    try {
      const formData = new FormData();
      formData.append('profilePic', profilePic);
      await axios.post(`https://blog-backend-ivfv.onrender.com/users/${userDetails._id}/profile-pic`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // Refresh user details to show updated profile picture
      const response = await axios.get(`https://blog-backend-ivfv.onrender.com/users/${userEmail}`);
      setUserDetails(response.data);
    } catch (error) {
      setError('Error uploading profile picture');
    }
  };

  if (!userEmail) {
    return <div className='App'>Email not provided</div>;
  }

  if (error) {
    return <div className='App'>{error}</div>;
  }

  if (!userDetails) {
    return <div className='App'>Loading...</div>;
  }

  const profilePicURL = `https://blog-backend-ivfv.onrender.com/${userDetails.profilePic}`;

  return (
    <div className='App'>
      <h2>Profile</h2>
      <div>
        <label>Name:</label>
        {isEditing ? (
          <input type='text' name='name' value={updatedDetails.name || ''} onChange={handleInputChange} />
        ) : (
          <span>{userDetails.name}</span>
        )}
      </div>
      <div>
        <label>Email:</label>
        <span>{userDetails.email}</span>
      </div>
      <div>
        <label>Description:</label>
        {isEditing ? (
          <textarea name='description' value={updatedDetails.description || ''} onChange={handleInputChange} />
        ) : (
          <span>{userDetails.description}</span>
        )}
      </div>
      {isEditing ? (
        <button onClick={handleSaveClick}>Save</button>
      ) : (
        <button onClick={handleEditClick}>Edit</button>
      )}
      <div>
        <h3>Update Profile Picture</h3>
        <input type='file' accept='image/*' onChange={handleFileChange} />
        <button onClick={handleUploadClick}>Upload</button>
      </div>
      {userDetails.profilePic && (
        <div>
          <h3>Current Profile Picture</h3>
          <img src={profilePicURL} alt='Profile' style={{ width: '200px', height: '200px' }} />
        </div>
      )}
    </div>
  );
};

export default Profile;
