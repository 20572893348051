import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Auth.css';

const Login = ({ setIsLoggedIn, setUserName, setUserEmail }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const storedName = localStorage.getItem('userName');
      const storedEmail = localStorage.getItem('useremail');
      if (storedName && storedEmail) {
        setUserName(storedName);
        setUserEmail(storedEmail);
      }
      setIsLoggedIn(true);
      navigate('/Addblogs');
    }
  }, [navigate, setIsLoggedIn, setUserName, setUserEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Email and password are required');
      return;
    }
    if (!isValidEmail(email)) {
      setError('Please use a valid company email');
      return;
    }
    
    try {
      const response = await fetch('https://blog-backend-ivfv.onrender.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Store the tokens in localStorage
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('userName', data.name);
        localStorage.setItem('useremail', data.email);
        setIsLoggedIn(true);
        setUserName(data.name);
        setUserEmail(data.email);
        navigate('/Addblogs');
      } else {
        if (response.status === 401 && data.error === 'Token expired') {
          // Handle token refresh
          const refreshToken = localStorage.getItem('refreshToken');
          const refreshResponse = await fetch('https://blog-backend-ivfv.onrender.com/refresh-token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
          });

          if (refreshResponse.ok) {
            const refreshedData = await refreshResponse.json();
            localStorage.setItem('accessToken', refreshedData.accessToken);
            setIsLoggedIn(true);
            setUserName(refreshedData.name);
            setUserEmail(refreshedData.email);
            navigate('/Addblogs');
          } else {
            setError('Session expired. Please login again.');
          }          
        } else {
          setError(data.error);
        }
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Internal Server Error');
    }
  };

  const isValidEmail = (email) => {
    return /\b@(walsystems\.in|walsystems\.com|bestowal\.in|bestowal\.com)\b/.test(email);
  };

  return (
    <div className="auth-container">
      <h2>Login</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit} className="auth-form">
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit">Login</button>
      </form>
      <p>Don't have an account? <Link to="/register">Register</Link></p>
      <p>Forgot your Password? <Link to="/forgot-password">Forgot Password</Link></p>
    </div>
  );
};

export default Login;
